$(document).on('click', 'a', function(event) {
  const link = $(this).attr('href');
  const modal = $('#externalLinkModal');

  if (!modal.length) {
    return;
  }

  if (link.startsWith('#') || link == '') {
    return;
  }

  url = new URL(link, window.location.href);

  const currentHost = window.location.host;

  if (url.host !== currentHost) {
    event.preventDefault();
    externalLink = url.href;

    modal.modal('show');

    $('#confirmExit').on('click', function() {
      window.open(externalLink, '_blank')
      modal.modal('hide');
    });
  }
});
