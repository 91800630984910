var merckceuticos = merckceuticos || {};

merckceuticos.nps = (function(){
    'use strict'

    function init(){
        bindingNextStep();
        bindingBackStep();
        bindingCloseModal();
    }

    function bindingNextStep() {
        $('.nps-content').on('click', '[data-step-next]', function(e) {
            e.preventDefault();

            let btnNextStepEl = $(this);
            let nextStep = btnNextStepEl.data('step-next');
            let questionContainerEl = $('#nps-container');
            let sections = questionContainerEl.find('section');

            $.each(sections, function() {
                let currentSection = $(this);

                currentSection.removeClass('d-block');
                currentSection.addClass('d-none');
            })

            let currentSection = $(`.question-step-${nextStep}`);
            currentSection.removeClass('d-none');
            currentSection.addClass('d-block');

            questionContainerEl.find('.current-step').html(nextStep);
        })
    }
    function bindingBackStep(){
        $('.nps-content').on('click', '[data-step-back]', function(e) {
            e.preventDefault();

            let btnbackStepEl = $(this);
            let backStep = btnbackStepEl.data('step-back');
            let questionContainerEl = $('#nps-container');
            let sections = questionContainerEl.find('section');

            $.each(sections, function() {
                let currentSection = $(this);
                currentSection.removeClass('d-block');
                currentSection.addClass('d-none');
            })

            let currentSection = $(`.question-step-${backStep}`);
            currentSection.removeClass('d-none');
            currentSection.addClass('d-block');

            questionContainerEl.find('.current-step').html(backStep);
        })
    }

    function bindingCloseModal(){
      $("body #modal-quiz").on('click', function(event){
        const postId = $(".finished-quiz").data('post')

        if (postId == undefined || postId == '') {
          return
        }

        if (event.target.id == 'modal-quiz' || event.target.className == 'button-close'){
          $.ajax({
            dataType: "script",
            type: 'GET',
            url: `/nps?post_id=${postId}`
          });
        }
      })

    }

    return {
     init: init
    };

}());

$(document).ready(function(){
    merckceuticos.nps.init();
});
