var merckceuticos = merckceuticos || {};

merckceuticos.login = (function(){

    function init(){
        getElementsPharmaceutical();
    }

    function getElementsPharmaceutical(){
        $('select#pharmaceutical_general').on('change', function(){
            if($(this).val() != 'Abrafarma'){
                $('.pharma-collapse').addClass('d-none');
                setValueInputPharmaceuticalId( $(this).val())
                return;
            }
            $('.pharma-collapse').removeClass('d-none');
            $('select#pharmaceutical_abrafarma').on('change', function(){
                setValueInputPharmaceuticalId($(this).val())
            });
        });
    }

    function setValueInputPharmaceuticalId(data){
        $('#user_pharmaceutical_id').val(data)
    }

    return {
        init: init
    };

}());

$(document).ready(function(){
    merckceuticos.login.init();
});
