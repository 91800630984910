var merckceuticos = merckceuticos || {};

merckceuticos.question = (function(){
    'use strict'

    function init(){
      bindingOpenModal();
      bindingNextStep();
      bindingBackStep();
      bindingConfirmQuestion()
    }

    function bindingConfirmQuestion() {
      $('.questions-content').on('click', '.confirm-question', function(e) {
        e.preventDefault();

        let el = $(this);

        confirmQuestion(el);
      });
    }

    function bindingNextStep() {
        $('.questions-content').on('click', '[data-step-next]', function(e) {
            e.preventDefault();

            let btnNextStepEl = $(this);
            let nextStep = btnNextStepEl.data('step-next');
            let questionContainerEl = $('#question-container');
            let sections = questionContainerEl.find('section');

            $.each(sections, function() {
                let currentSection = $(this);

                currentSection.removeClass('d-block');
                currentSection.addClass('d-none');
            })

            let currentSection = $(`.question-step-${nextStep}`);
            currentSection.removeClass('d-none');
            currentSection.addClass('d-block');

            questionContainerEl.find('.current-step').html(nextStep);
        })
    }
    function bindingBackStep(){
        $('.questions-content').on('click', '[data-step-back]', function(e) {
            e.preventDefault();

            let btnbackStepEl = $(this);
            let backStep = btnbackStepEl.data('step-back');
            let questionContainerEl = $('#question-container');
            let sections = questionContainerEl.find('section');

            $.each(sections, function() {
                let currentSection = $(this);
                currentSection.removeClass('d-block');
                currentSection.addClass('d-none');
            })

            let currentSection = $(`.question-step-${backStep}`);
            currentSection.removeClass('d-none');
            currentSection.addClass('d-block');

            questionContainerEl.find('.current-step').html(backStep);
        })
    }
    function bindingOpenModal() {
        $('body').on('click', '.open-quiz', function() {
          let postId = $(this).data('post-id');

          openAndRenderModal(postId);
        })
    }

    function openAndRenderModal(post_id){
        $.ajax({
            dataType: "script",
            type: 'GET',
            url: `/posts/${post_id}/questions/new`
        });
    }

    function confirmQuestion(el) {
      let section = el.closest('section');

      if(section.find('input:checked').length > 0) {
        section.removeClass('mode-confirm');
        section.addClass('answer-mode');
      } else {
        alert('Favor selecionar alternativa.');
      }
    }

    return {
     init: init
    };

}());

$(document).ready(function(){
    merckceuticos.question.init();
});
