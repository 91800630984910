var merckceuticos = merckceuticos || {};

merckceuticos.content = (function(){
    'use strict'

    function init(){
        getElementNotification();
        getNotification();
    }

    function getElementNotification(){
        $('body').on('click', '.icone-home-content > img', function(event){
            //event.preventDefault()
            openBoxNotification();
        })
    }

    function openBoxNotification(){
        $('.box-notification-content').fadeToggle().toggleClass('d-block')
    }

    function getNotification(){
        $('body').on('click', '.content-notification-content', function(){
            sendNotification($(this));
        })
    }

    function sendNotification(element){
        $.ajax({
            type: "POST",
            url: `/posts/${$(element).data('post-id')}/view_notification`,
            beforeSend: function(xhr) {
              xhr.setRequestHeader(
                'X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')
              )
            },
            data: {
              _method:'PUT',
              post: {
                view_notification: 'true'
              }
            },
            dataType: 'json',
            success: function(elementId) {
                removeNotification(elementId)
            }
        });
    }

    function removeNotification(elementId){
        $("ul").find(`[data-post-id=${elementId}]`).fadeOut()
    }

    return {
        init: init
    };

}());

$(document).ready(function(){
    merckceuticos.content.init();


    $('body').on('click', '.post-actions .label', function(){
      $(this).parent().find('button').trigger('click');
    });

    $('.participe').click(function(){
      $(this).parent().find('button.challenge').click();
    });

    $('.post.comment').click(function(){
      $(this).parent('.border-post').find('.input-comments').focus();
    });


    $('.btn-cadastre').click(function(){
        $('html, body').animate({
            scrollTop: $("#cadastro").offset().top
        }, 500);
    });

});
