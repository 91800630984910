var merckceuticos = merckceuticos || {};

merckceuticos.comment = (function(){
    'use strict'

    function init(){
        getElementNotification();
        getNotification();
        openBoxNotification();
    }

    function getElementNotification(){
        $('body').on('click', '.notification > img', function(){
            openBoxNotification()
        })
    }

    function openBoxNotification(){
        $('body').one('click', '.icone-notificacao button.btn.notification', () => {
            $('.box-notification-comment').fadeToggle().toggleClass('d-block')
        })
    }

    function getNotification(){
        $('body').on('click', '.content-notification-comment', function(){
            sendNotification($(this));
        })
    }

    function sendNotification(element){
        $.ajax({
            type: "POST",
            url: `/comments/${$(element).data('comment-id')}/view_notification`,
            beforeSend: function(xhr) {
              xhr.setRequestHeader(
                'X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')
              )
            },
            data: {
              _method:'PUT',
              comment: {
                view_notification: 'true'
              }
            },
            dataType: 'json',
            success: function(elementId) {
                removeNotification(elementId)
            }
        });
    }

    function removeNotification(elementId){
        $("ul").find(`[data-comment-id=${elementId}]`).fadeOut()
    }

    return {
        init: init
    };

}());

$(document).ready(function(){
    merckceuticos.comment.init();
});
