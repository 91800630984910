$(window).on("load", function () {
  triggerButtonActions();
})

function triggerButtonActions() {
  $('body').on('click', 'button.like', function () {
    var post_id = $(this).data('post-id')

    if ($(this).hasClass('like-active')) {
      unlike(post_id)
    } else {
      like(post_id)
    }
  })
}

function unlike(post_id) {
  $.ajax('/votes', {
    method: 'DELETE',
    data: { vote: { post_id: post_id, value: 1 } }
  }).then(function (response) {
    if (response.status == 201) {
      getMessage(post_id)
      $(`.post.like[data-post-id=${post_id}]`).removeClass('like-active')
    }
  });
}

function like(post_id) {
  $.ajax('/votes', {
    method: 'POST',
    data: { vote: { post_id: post_id, value: 1 } }
  }).then(function (response) {
    if (response.status == 201) {
      getMessage(post_id)
      $(`.post.like[data-post-id=${post_id}]`).addClass('like-active')
    }
  });
}

function getMessage(post_id) {
  $.ajax('/votes/get_message', {
    method: 'POST',
    data: { vote: { post_id: post_id } }
  }).then(function (response) {
    if (response.status == 201) {
      setMessage(post_id, response.message)
    }
  });
}

function setMessage(post_id, message = null) {
  $(`#message-${post_id}`).html(message)
}
